import './component.scss'

import './images/tag.png'
import './images/letter.png'
import './images/arrow_block_right.svg'
import './images/icon_bath.png'
import './images/icon_bath_blue.png'
import './images/icon_kitchen.png'
import './images/icon_kitchen_blue.png'
import './images/icon_toilet.png'
import './images/icon_toilet_blue.png'
import './images/icon_washroom.png'
import './images/icon_washroom_blue.png'
import './images/menu-panel-bath.png'
import './images/menu-panel-kitchen.png'
import './images/menu-panel-toilet.png'
import './images/menu-panel-washroom.png'
import './images/tel_icon.png'

import '@stimulus/polyfills'
import { Application, Controller } from 'stimulus'

import $ from 'jquery'

const app = Application.start()

app.register('sp-open-menu', class extends Controller {
  initialize () {
    this.showSliderNavigationContent = document.getElementById('js-site-slider-navigation')
  }

  // メニューを開く
  openMenu () {
    this.showSliderNavigationContent.classList.add('site-slider-navigation--show')
    document.body.style.position = 'fixed'
  }

  // メニューを閉じる
  closeMenu (e) {
    // クリックした要素が閉じるボタンかどうか
    const isCloseButton = e.target.closest('.site-slider-navigation__close-button') !== null
    // クリックした要素がメニューの外側かどうか
    const isOutsideClick = e.target.closest('.site-slider-navigation__body') === null

    // 閉じるボタンや外部クリック時にメニューを閉じる
    if (isCloseButton || isOutsideClick) {
      this.showSliderNavigationContent.classList.remove('site-slider-navigation--show')
      document.body.style.position = ''
    }
  }
})
