import './component.scss'
import 'Components/refosumu/company_image/component'
import $ from 'jquery'

import { Application, Controller } from 'stimulus'

const app = Application.start()

app.register('carousel-light', class extends Controller {
  connect() {
    this.resize()
  }

  resize() {
    const $this = $(this.element)
    const options = this.sliderOptions()
    const $slider = $this.find('.slider')
    const windowWidth = $(window).innerWidth()
    const sliderWidth = $slider.innerWidth()

    // 1画面に何枚を表示するか決める
    let slidesToShow = 1
    if (windowWidth > options.breakpoint && options.pc && options.pc.slidesToShow) {
      slidesToShow = options.pc.slidesToShow
    }
    else if (options.slidesToShow) {
      slidesToShow = options.slidesToShow
    }

    // カルーセルの中央になるように調整する
    const $sliderItems = $slider.children()
    let width = sliderWidth
    if (windowWidth > options.breakpoint && options.pc && options.pc.width) {
      width = options.pc.width
    }
    else if (options.width) {
      width = options.width
    }
    $sliderItems.css('width', width / slidesToShow + 'px')

    // 中央に来るよう揃える
    const margin = (sliderWidth - width) / slidesToShow
    $sliderItems.css('margin-left', margin / 2 + 'px')
    $sliderItems.css('margin-right', margin / 2 + 'px')

    const arrowLimit = $sliderItems.length / slidesToShow - 1
    this.layout($this, $slider, width + margin, arrowLimit)
  }

  layout ($this, $slider, scroll, arrowLimit) {
    const $arrowPrev = $this.find('.arrow--prev')
    const $arrowNext = $this.find('.arrow--next')
    const $pagination = $this.find('.pagination')
    const $sliderItems = $slider.children()
    $sliderItems.addClass('slider-item')

    const options = this.sliderOptions()
    if (options.dots === true) {
      for (let i = 0; i < arrowLimit; i++) {
        $pagination.append('<span class="pagination-dot"></span>')
      }
    }

    const $dots = $this.find('.pagination-dot')
    $dots.eq(0).addClass('active')

    let counter = 0
    checkArrow()
    $arrowPrev.click(function () {
      clickPrev()
    })
    $arrowNext.click(function () {
      clickNext()
    })
    $dots.click(function () {
      const newCounter = $dots.index(this)
      moveSlider(newCounter)
    })

    // NOTE: SP用のタッチイベントは暴発するので矢印だけの操作にする
    // https://github.com/speee/dx-nurikae/pull/9120

    $this.on('mousedown', onTouchStart)
    $this.on('mousemove', onTouchMove)
    $this.on('mouseup', onTouchEnd)
    let direction, position

    function onTouchStart (event) {
      position = getPosition(event)
      direction = ''
    }

    function onTouchMove (event) {
      // 50px以上でスワイプする
      if (position - getPosition(event) > 50) {
        direction = 'left'
      }
      else if (position - getPosition(event) < -50) {
        direction = 'right'
      }
    }

    function onTouchEnd (_event) {
      if (direction === 'right') {
        clickPrev()
      }
      else if (direction === 'left') {
        clickNext()
      }
    }

    // 横方向の座標を取得する
    function getPosition (event) {
      // SPのとき
      if (event.changedTouches) {
        return event.changedTouches[0].pageX
      }
      // PCのとき
      else {
        return event.clientX
      }
    }

    function clickPrev () {
      if (counter > 0) {
        const newCounter = counter - 1
        moveSlider(newCounter)
      }
    }

    function clickNext () {
      if (counter < arrowLimit) {
        const newCounter = counter + 1
        moveSlider(newCounter)
      }
    }

    function checkArrow () {
      if (counter <= 0) {
        $arrowPrev.css('display', 'none')
      }
      else {
        $arrowPrev.css('display', 'block')
      }
      if (counter >= arrowLimit) {
        $arrowNext.css('display', 'none')
      }
      else {
        $arrowNext.css('display', 'block')
      }
    }

    function moveSlider (newCounter) {
      $dots.eq(counter).removeClass('active')
      counter = newCounter
      $dots.eq(counter).addClass('active')
      $slider.css('margin-left', (-1) * counter * scroll + 'px')
      checkArrow()
    }
  }

  sliderOptions () {
    return JSON.parse(this.element.dataset.sliderOptions)
  }
})
