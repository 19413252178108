/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function (definition) {
  const root = ((typeof self === 'object') && (self.self === self) && self) || ((typeof global === 'object') && (global.global === global) && global)

  if (typeof exports === 'object') {
    return module.exports = definition(root, require('jquery'))
  } else {
    return root.spToggleNav = definition(root, root.$)
  }
})(function (root, $) {
  const trimDot = string => string.replace('.', '')

  return function (obj) {
    obj = obj || {}
    obj.btn = obj.btn || '.js-toggleMenu'
    obj.page = obj.page || '.l-page'
    obj.body = obj.body || '.l-body'
    obj.overlay = obj.overlay || '.l-page__overlay'
    obj.nav = obj.nav || '.l-spNav'
    obj.addClass = obj.addClass || 'movemenu'
    obj.beforeEle = obj.beforeEle || '.l-copyright'
    obj.footer = obj.footer || '.l-footer'
    obj.header = obj.header || '.l-header'

    const $document = $('html, body')
    const $page = $(obj.page)
    const $btn = $(obj.btn)
    const $body = $(obj.body)
    const $beforeEle = $(obj.beforeEle)
    const $footer = $(obj.footer)
    const $header = $(obj.header)

    const toggleNav = function () {
      $page.append(`<div class='${trimDot(obj.overlay)}'>`)
      if (!($('.l-header__menuBtn').hasClass('column-menu'))) {
        $footer.append(`<div class='${trimDot(obj.overlay)}'>`)
      }
      $header.addClass('slide-open')
      $header.removeClass('slide-close')
      const $overlay = $(obj.overlay)
      $overlay.delay(300).queue(function () {
        $(this).toggleClass('overlay__close_btn').dequeue()
      })
      $body.append($beforeEle)

      $document.addClass(obj.addClass)

      $overlay.on('touchstart', function () {
        $('.l-spNav').after($beforeEle)
        $header.removeClass('slide-open')
        $header.addClass('slide-close')
        $document.removeClass(obj.addClass)
        return $page.on('webkitTransitionEnd transitionEnd', function () {
          $overlay.off('touchstart').remove()
          return $page.off('webkitTransitionEnd transitionEnd')
        })
      })
      return false
    }

    // ハンバーガーメニュー内コラムカテゴリーの開閉
    $('.columnCategory__heading').on('click', function () {
      $header.toggleClass('slide-open')
    })

    return $btn.on('touchend', toggleNav)
  }
})
