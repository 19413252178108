import './component.scss'
import './images/no_image.png'

var $ = require('jquery')
require('slick-carousel')

$(function () {
  $('.js-example-card-carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 800,
    dots: false,
    infinite: false,
    arrows: true,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '0',
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '0',
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
})
