import '@stimulus/polyfills'
import { Application, Controller } from 'stimulus'

import './component.scss'

const app = Application.start()

app.register('close-button', class extends Controller {
  closeModal(e) {
    if (this.isCloseButton(e) || this.isOutsideModal(e)) {
      const modal = document.getElementsByClassName('search-menu')[0]
      modal.classList.remove('search-menu--show')
    }
  }

  isCloseButton(e) {
    const searchMenuCloseButton = document.getElementsByClassName('search-menu__close-button')[0]
    const isSearchMenuCloseButton = (e.target == searchMenuCloseButton)
    const isChildClass = (e.target.parentNode == searchMenuCloseButton)

    return (isSearchMenuCloseButton || isChildClass)
  }

  isOutsideModal(e) {
    return e.target.lastElementChild == document.getElementsByClassName('search-menu__body')[0]
  }
})
