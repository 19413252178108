import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './component.scss'

global.jQuery = $
require('jquery-ujs')

// ルートクラス定義
const ROOT_TARGET_CLASS_NAME = 'js-helpfulWidget-root-target'
const ROOT_TARGET_SELECTOR = `.${ROOT_TARGET_CLASS_NAME}`

const helpfulButton = () => {
  // セレクタ定義
  const BUTTON_TARGET_CLASS_NAME = 'js-helpfulWidget-button-target'
  const BUTTON_TARGET_SELECTOR = `.${BUTTON_TARGET_CLASS_NAME}`

  const COUNTER_NUMBER_TARGET_CLASS_NAME = 'js-helpfulWidget-counterNumber-target'
  const COUNTER_NUMBER_TARGET_SELECTOR = `.${COUNTER_NUMBER_TARGET_CLASS_NAME}`

  // data属性の定義
  const INTRODUCE_REVIEW_ID_DATA_NAME = 'introduce-review-id'
  const COUNTER_NUMBER_DATA_NAME = 'counter-number'

  // 未評価状態のクラス定義
  const UNRATED_CLASS_NAME = 'is-unrated'

  // 完了状態のクラス定義
  const DONE_CLASS_NAME = 'is-done'

  // ローカルストレージに保存するkey名の定義
  const LOCAL_STORAGE_KEY_NAME = 'nurikae_already_helpful_list'

  // ローカルストレージから押したボタンのリストを取得
  const alreadyHelpfulList = window?.localStorage?.getItem(LOCAL_STORAGE_KEY_NAME)?.split(',')?.filter((v) => v) || []

  // 処理
  const $rootElement = $(ROOT_TARGET_SELECTOR)

  $rootElement.each((index, root) => {
    const introduceReviewId = $(root).data(INTRODUCE_REVIEW_ID_DATA_NAME)
    const $buttonTarget = $(root).find(BUTTON_TARGET_SELECTOR)
    const $counterNumberTarget = $(root).find(COUNTER_NUMBER_TARGET_SELECTOR)
    const currentCounterNumber = Number($counterNumberTarget.data(COUNTER_NUMBER_DATA_NAME))

    // カウントアップ関数
    const countUpCounterNumber = ($target, currentNumber) => {
      const afterNumber = currentNumber + 1
      if (afterNumber <= 999) {
        $target.text(afterNumber)
      } else {
        $target.text('999+')
      }
    }

    // 既に参考になったボタンを押したユーザーの場合は完了状態に変更する
    if (currentCounterNumber !== 0 && alreadyHelpfulList.includes(String(introduceReviewId))) {
      $(root).addClass(DONE_CLASS_NAME)
    }

    // 参考になったボタンを押したときの処理
    $buttonTarget.on('click', () => {
      // 1回以上評価された状態に変更
      $(root).removeClass(UNRATED_CLASS_NAME)

      // 既に評価済み完了状態に変更
      $(root).addClass(DONE_CLASS_NAME)

      // カウンターに+1する処理
      countUpCounterNumber($counterNumberTarget, currentCounterNumber)

      // 詳細ページの場合、ページ内に同一機能のボタンがあるので該当ボタンと同じ処理を行う
      $rootElement.not($(root)).each((index, siblingsRoot) => {
        const siblingsIntroduceReviewId = $(siblingsRoot).data(INTRODUCE_REVIEW_ID_DATA_NAME)

        if (siblingsIntroduceReviewId == introduceReviewId) {
          const $siblingsCounterNumberTarget = $(siblingsRoot).find(COUNTER_NUMBER_TARGET_SELECTOR)
          const siblingsCurrentCounterNumber = Number($siblingsCounterNumberTarget.data(COUNTER_NUMBER_DATA_NAME))

          // 1回以上評価された状態に変更
          $(siblingsRoot).removeClass(UNRATED_CLASS_NAME)

          // 既に評価済み完了状態に変更
          $(siblingsRoot).addClass(DONE_CLASS_NAME)

          // カウンターに+1する処理
          countUpCounterNumber($siblingsCounterNumberTarget, siblingsCurrentCounterNumber)
        }
      })

      // ローカルストレージに該当IDを追加する
      alreadyHelpfulList.push(introduceReviewId)
      window?.localStorage?.setItem(LOCAL_STORAGE_KEY_NAME, alreadyHelpfulList.join(','))
    })
  })
}

$(() => {
  // 参考になったボタンを押したときの処理
  helpfulButton()
})
