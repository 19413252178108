import './component.scss'
import './images/kaeru.png'
import './images/ico_arrow.png'

const $ = require('jquery')
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 300) {
    $('.floating').fadeIn('slow')
  }
  else {
    $('.floating').fadeOut('slow')
  }
})
