import '@stimulus/polyfills'
import { Application, Controller } from 'stimulus'

import './component.scss'

const app = Application.start()

app.register('search-widget-button', class extends Controller {
  showLinkModal () {
    const searchMenuContent = document.getElementById('search-menu-content')
    const searchMenu = searchMenuContent.firstElementChild

    searchMenu.classList.add('search-menu--show')
  }
})
